import React, { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrayHelpers, FieldArray } from 'formik';
import { CustomButton, FormTextField, RoundedAvatar } from '../../../../../components';
import uniqId from 'uniqid';
import { AddIcon } from '../../../../../assets/icons/AddIcon';
import { ClassTeam } from '../ClassesTag/ClassesTag';
import { Accordion, Card, InputGroup, Form } from 'react-bootstrap';
import { CustomAccordionHeader } from '../../../common/components/CustomAccordionHeader';
import { MemberTournamentCard } from '../../../../../components';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import { classTypesData } from '../../../../../constant/class';
import { ModalAction, ModalName } from '../../../../../types/modal';
import { openModal } from '../../../../../features/modal/modalSlice';
import { CloseCircle } from '../../../../../assets/icons/CloseCircle';
import './index.scss';
import { ClassTypeEnum, TeamTournamentType } from '../../../../../types/enums';
import { shuffleTeams } from './TeamSection.helpers';
import { ShuffleIcon } from '../../../../../assets/icons/ShuffleIcon';
import { ReactSortable } from 'react-sortablejs';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { uploadImage } from '../../../../../apis/tournament';
import { RootState } from '../../../../../app/store';
import { useGetCurrentOrganization } from '../../../../../features/organizations/organizationsSelectors';

interface TeamSectionProps {
    teams: ClassTeam[];
    setField: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const TeamSection: FC<TeamSectionProps> = (props) => {
    const { t } = useTranslation();
    const { teams, setField } = props;
    const [activeKey, setActiveKey] = useState('' as any);
    const [draggedId, setDraggedId] = useState<string | number | null>(null);
    const currentType = useAppSelector((state: RootState) => state.class.currentType);

    const currentOrganization = useGetCurrentOrganization();

    const type = classTypesData.find((el) => el.value === currentType);
    const dispatch = useAppDispatch();
    const isTeamLeague = type?.value === ClassTypeEnum.TEAMS;
    // We lock the number of teams to 12. But BE can support more, so this value can be increased without any issues.
    const isTeamLeagueMax = isTeamLeague && teams.length >= 12;

    const prefix = 'creativeDetail-';
    const inputPrefix = 'input-';
    const modalPrefix = 'modal-';
    const buttonPrefix = 'button-';

    const showShuffleButton = teams.find(({ seed, shuffled }) => !seed || shuffled);

    const handleOpenModal = (teamId: string | number) => {
        dispatch(
            openModal({
                currentModal: ModalAction.ADD_MEMBER,
                modalOpen: true,
                data: {
                    teams,
                    setField,
                    teamId,
                    type,
                    title: t(modalPrefix + 'addPlayers'),
                    modalName: ModalName.ADD_TEAM_PLAYERS_CLASS,
                },
            })
        );
    };

    const handleOpenCaptainModal = (teamId: string | number) => {
        dispatch(
            openModal({
                currentModal: ModalAction.ADD_CAPTAIN,
                modalOpen: true,
                data: {
                    teams,
                    setField,
                    teamId,
                    type,
                    title: t(modalPrefix + 'addCaptain'),
                    modalName: ModalName.ADD_TEAM_CAPTAIN_CLASS,
                },
            })
        );
    };

    const onChange = (imageList: ImageListType, id: string | number) => {
        const formData = new FormData();
        formData.append('file', imageList[0]?.file || '');
        imageList[0].file &&
            uploadImage(formData, 'team')
                .then((data) => {
                    const updatedTeams = teams.map((team) =>
                        team.id === id ? { ...team, logo: data.url } : team
                    );
                    setField('teams', updatedTeams);
                })
                .catch((e) => {
                    console.log(e);
                });
    };


    const handleOpenTeamModal = useCallback(
        (push: (obj: any) => void) => {
            dispatch(
                openModal({
                    currentModal: ModalAction.ADD_TEAM,
                    modalOpen: true,
                    data: {
                        selected: teams,
                        title: t(modalPrefix + 'addTeam'),
                        push,
                    },
                })
            );
        },
        [dispatch, t, teams]
    );

    return (
        <FieldArray name="teams">
            {({ push, remove }: ArrayHelpers) => (
                <>
                    <div className="my-3 d-flex justify-content-between align-items-center">
                        <h4 className="font-weight-bold">{t(prefix + 'teams')}</h4>
                        <div className="d-flex">
                            {showShuffleButton && (
                                <CustomButton
                                    variant="dark"
                                    btnText={t(prefix + 'shuffleRank')}
                                    cb={() => {
                                        const teamsToUpdate = shuffleTeams(teams);
                                        const newTeams = teamsToUpdate.sort(
                                            (a, b) => a.seed - b.seed
                                        );
                                        setField('teams', [
                                            ...newTeams.filter((i) => Boolean(i.seed)),
                                            ...newTeams.filter((i) => !Boolean(i.seed)),
                                        ]);
                                    }}
                                    className="d-flex justify-content-center align-items-center me-1"
                                >
                                    <ShuffleIcon h="14" w="14" />
                                </CustomButton>
                            )}
                            <CustomButton
                                variant="outline-dark"
                                btnText={t(prefix + 'addTeam')}
                                disabled={isTeamLeagueMax}
                                cb={() => {
                                    push({
                                        id: uniqId(),
                                        name: `Team ${teams.length + 1}`,
                                        users: [],
                                        captain: null,
                                        logo: null,
                                        seed: 0,
                                        organization: currentOrganization,
                                        type: isTeamLeague ? TeamTournamentType.Team : TeamTournamentType.Regular,
                                    });
                                }}
                                className="d-flex justify-content-between align-items-center me-1"
                            >
                                <AddIcon />
                            </CustomButton>
                            {isTeamLeague && (
                                <CustomButton
                                    variant="outline-dark"
                                    btnText={t(prefix + 'addExistingTeam')}
                                    disabled={isTeamLeagueMax}
                                    cb={() => handleOpenTeamModal(push)}
                                    className="d-flex justify-content-between align-items-center"
                                >
                                    <AddIcon />
                                </CustomButton>
                            )}
                        </div>
                    </div>
                    {teams.length ? 
                        (<ReactSortable list={[...teams].map(v => ({ ...v, chosen: true}))} setList={(updatedList, event, store) => {
                            if(store?.dragging) {
                                setField('teams', updatedList.map(({chosen, ...rest}, i) => {
                                    if(rest.seed || rest.id === draggedId) {
                                        rest.seed = i + 1;

                                        if(rest.id === draggedId) {
                                            rest.shuffled = false;
                                        }
                                    };
                                    return rest;
                                }));
                            }
                        }}>
                            { teams.map((team, index) => {
                                    const isBtnActive =
                                        (type?.value === ClassTypeEnum.SINGLES && team.users?.length >= 1) ||
                                        (type?.value === ClassTypeEnum.DOUBLES && team.users?.length >= 2) ||
                                        false;
                                    return (
                                        <div key={index} className="container-fluid px-0 my-3"
                                            onMouseDown={() => setDraggedId(team.id)} 
                                            onTouchStart={() => setDraggedId(team.id)}
                                        >
                                            <Accordion alwaysOpen={false} activeKey={activeKey} onSelect={setActiveKey} className="my-3">
                                                <Card>
                                                    <Card.Header className="p-0 bg-white border-0">
                                                        <CustomAccordionHeader
                                                            eventKey={index.toString()}
                                                        >
                                                            <InputGroup className="flex-nowrap">
                                                                <Form.Text className="mt-0">
                                                                    <div className="d-flex p-2 h-100 flex-column justify-content-center">
                                                                        {(team.logo && (<RoundedAvatar
                                                                            name="flag"
                                                                            avatar={team.logo}
                                                                            alt="Team logo"
                                                                        />)) || (<div className="raqt__seed-holder">
                                                                                        {team.seed}
                                                                                    </div>)}
                                                                    </div>
                                                                </Form.Text>
                                                                <FormTextField
                                                                    className="py-0 border-1 w-100 border-white"
                                                                    index={index}
                                                                    name={`teams.${index}.name`}
                                                                    placeholder={t(
                                                                        inputPrefix +
                                                                            'placeholderTeamName'
                                                                    )}
                                                                    type="text"
                                                                />
                                                                <input type="hidden" name={`teams.${index}.shuffled`} />
                                                            </InputGroup>
                                                        </CustomAccordionHeader>
                                                    </Card.Header>
                                                    <Accordion.Collapse eventKey={index.toString()}>
                                                        {(activeKey === index.toString() && (<Card.Body className="p-2">
                                                            <div className="w-100 d-flex flex-wrap flex-column flex-lg-row">
                                                                <div className="col-lg-3 d-flex flex-column flex-lg-row align-items-end align-items-lg-start px-0 px-lg-2">
                                                                    <div className="d-flex flex-column">
                                                                        {
                                                                            isTeamLeague && (
                                                                                <>
                                                                                    {
                                                                                        team.logo && (
                                                                                            <img
                                                                                                style={{ objectFit: 'cover', width: '100%', maxWidth: '240px', aspectRatio: '1/1' }}
                                                                                                className={'rounded-circle'}
                                                                                                src={team.logo}
                                                                                                alt={team.name + ' team logo'}
                                                                                            />
                                                                                        )
                                                                                    }

                                                                                    <ImageUploading value={[]}
                                                                                        onChange={(
                                                                                            imageList: ImageListType
                                                                                        ) => {
                                                                                            onChange(imageList, team.id);
                                                                                        }}>
                                                                                        {({ onImageUpload }) => (
                                                                                            <CustomButton variant="outline-dark" btnText={t(
                                                                                                buttonPrefix +
                                                                                                    'addTeamIcon'
                                                                                            )} cb={onImageUpload} />
                                                                                        )}
                                                                                    </ImageUploading>
                                                                                </>
                                                                            )
                                                                        }
                                                                        {   
                                                                            isTeamLeague && (
                                                                                <CustomButton
                                                                                    disabled={isBtnActive}
                                                                                    className="d-flex justify-content-evenly align-items-center"
                                                                                    variant="outline-dark"
                                                                                    btnText={t(
                                                                                        buttonPrefix + 'addCaptain'
                                                                                    )}
                                                                                    cb={() => {
                                                                                        handleOpenCaptainModal(team.id);
                                                                                    }}
                                                                                >
                                                                                    <AddIcon />
                                                                                </CustomButton>
                                                                            )
                                                                        }
                                                                        <CustomButton
                                                                            disabled={isBtnActive}
                                                                            className="d-flex justify-content-evenly align-items-center"
                                                                            variant="outline-dark"
                                                                            btnText={t(
                                                                                buttonPrefix + 'addPlayers'
                                                                            )}
                                                                            cb={() => {
                                                                                handleOpenModal(team.id);
                                                                            }}
                                                                        >
                                                                            <AddIcon />
                                                                        </CustomButton>
                                                                    </div>
                                                                </div>
                                                                <div className={`col d-flex flex-wrap ${isTeamLeague ? 'flex-column col-lg-4' : ''} px-0`}>
                                                                    {   
                                                                        isTeamLeague && team.captain && team.captain.length > 0 && team.captain[0]?.id ? (
                                                                            <> 
                                                                                <h5 className="font-weight-normal mb-0">{t(prefix + 'teamCaptain')}</h5>
                                                                                <div>
                                                                                    <React.Fragment
                                                                                    
                                                                                    >
                                                                                        <MemberTournamentCard
                                                                                            member={
                                                                                                team.captain[0]
                                                                                            }
                                                                                        />
                                                                                    </React.Fragment>
                                                                                </div>
                                                                            </>
                                                                        ) : (
                                                                            <div className="d-flex justify-content-center align-items-center">
                                                                                <p className="fw-lighter mb-0">
                                                                                    {t(
                                                                                        prefix +
                                                                                            'chooseCaptain'
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        ) 
                                                                    }
                                                                    <FieldArray
                                                                        name={`teams.${index}.users`}
                                                                    >
                                                                        {({ remove, replace }: ArrayHelpers) => {
                                                                            //   replace(type?.value === ClassTypeEnum.SINGLES ? 1 : 2, undefined);
                                                                            return team.users.length ? (
                                                                                <>
                                                                                    {
                                                                                    team.users
                                                                                    .filter((v, index) => {
                                                                                        const maxLength = type?.value === ClassTypeEnum.SINGLES ? 1 : 2;
                                                                                        return isTeamLeague ? true : index < maxLength;
                                                                                    })
                                                                                    .map(
                                                                                        (user, i) => (
                                                                                            <div key={user.id}>
                                                                                                {
                                                                                                   i === 0 && isTeamLeague ? (
                                                                                                        <h5 className="font-weight-normal mb-0 mt-3">{t(prefix + 'teamPlayers')}</h5>
                                                                                                    ) : null
                                                                                                }
                                                                                                <React.Fragment
                                                                                                
                                                                                                >
                                                                                                    <MemberTournamentCard
                                                                                                        onClick={() => {
                                                                                                            if(type?.value === ClassTypeEnum.SINGLES && team.users.length === 2) {
                                                                                                                remove(
                                                                                                                    1
                                                                                                                );
                                                                                                            }
                                                                                                            remove(
                                                                                                                i
                                                                                                            );
                                                                                                        }}
                                                                                                        member={
                                                                                                            user
                                                                                                        }
                                                                                                    />
                                                                                                </React.Fragment>
                                                                                            </div>
                                                                                        )
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <div className="d-flex justify-content-center align-items-center">
                                                                                    <p className="fw-lighter mb-0">
                                                                                        {t(
                                                                                            prefix +
                                                                                                'choosePlayers'
                                                                                        )}
                                                                                    </p>
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    </FieldArray>
                                                                </div>
                                                            </div>
                                                            <div className="col-5 col-lg-3 p-2">
                                                                <FormTextField
                                                                    label={t(prefix + 'seed')}
                                                                    className="py-0 border-1 w-100"
                                                                    index={index}
                                                                    name={`teams.${index}.seed`}
                                                                    placeholder={t(
                                                                        inputPrefix + 'placeholderSeed'
                                                                    )}
                                                                    type="text"
                                                                />
                                                            </div>
                                                            <div className="d-flex justify-content-start justify-content-lg-end">
                                                                <div className="flex-grow-1">
                                                                    {/* <ImageUploading
                                                                        value={[]}
                                                                        onChange={(
                                                                            imageList: ImageListType
                                                                        ) => {
                                                                            onChange(imageList, team.id);
                                                                        }}
                                                                    >
                                                                        {({ onImageUpload }) => {
                                                                            return (
                                                                                <div className="d-flex justify-content-start px-0 px-lg-2">
                                                                                    <CustomButton
                                                                                        className="d-flex justify-content-evenly align-items-center"
                                                                                        variant="outline-dark"
                                                                                        btnText={t(
                                                                                            buttonPrefix +
                                                                                                'addTeamIcon'
                                                                                        )}
                                                                                        cb={onImageUpload}
                                                                                    />
                                                                                </div>
                                                                            );
                                                                        }}
                                                                    </ImageUploading> */}
                                                                </div>
                                                                <CustomButton
                                                                    className="d-flex align-items-center"
                                                                    btnText={t(
                                                                        buttonPrefix + 'removeTeam'
                                                                    )}
                                                                    variant="outline-dark"
                                                                    onClick={() => {
                                                                        remove(index);
                                                                    }}
                                                                >
                                                                    <CloseCircle />
                                                                </CustomButton>
                                                            </div>
                                                        </Card.Body>)) || <Card.Body><div className="team-section__dummy"></div></Card.Body>}
                                                    </Accordion.Collapse>
                                                </Card>
                                            </Accordion>
                                        </div>
                                    );
                                })
                            }
                        </ReactSortable>) : null}
                </>
            )}
        </FieldArray>
    );
};
